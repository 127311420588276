import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/ModeToggle.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/NavbarDesktop.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/NavbarMobile.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Separator"] */ "/vercel/path0/components/ui/separator.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Root","displayName","List","Item","Trigger","Content","Link","Viewport","Indicator"] */ "/vercel/path0/node_modules/@radix-ui/react-navigation-menu/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
